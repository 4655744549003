<template>
  <div class="edit-user-cards">
<!--    <div class="column column-edit-contact"-->
<!--         :class="{'column-busy' : this.editContactLoading}"-->
<!--         style="display: none !important;"-->
<!--    >-->
<!--      <h3 class="column-title">{{ $t('contacts.popups.editContact.vCardInfo') }}</h3>-->
<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.photo') }}</div>-->
<!--      <div>-->
<!--        <upload-image-->
<!--            ref="userPhotoUploader"-->
<!--            class="upload-image-user"-->
<!--            :imageUrl="formData.contact.photo_url"-->
<!--            @imageData="handlePhotoUrl"-->
<!--        ></upload-image>-->
<!--      </div>-->
<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.profile') }}</div>-->

<!--      <div class="control" :set="control = $v.formData.contact.name">-->
<!--        <mu-text-field-->
<!--          v-model="formData.contact.name"-->
<!--          label-float-->
<!--          full-width-->
<!--          max-length="250"-->
<!--          :label="$t('contacts.popups.editContact.placeholders.first_name')"-->
<!--        ></mu-text-field>-->
<!--        <div class="error-messages" v-if="control.$invalid">-->
<!--          <div v-if="!control.required" class="error-message">{{ $t('errors.validation.required') }}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="control" :set="control = $v.formData.contact.surname">-->
<!--        <mu-text-field v-model="formData.contact.surname"-->
<!--                       label-float-->
<!--                       full-width-->
<!--                       max-length="250"-->
<!--                       :label="$t('contacts.popups.editContact.placeholders.last_name')"-->
<!--        ></mu-text-field>-->
<!--        <div class="error-messages" v-if="control.$invalid">-->
<!--          <div v-if="!control.required" class="error-message">{{ $t('errors.validation.required') }}</div>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div class="control" :set="control = $v.formData.contact.email">-->
<!--        <mu-text-field-->
<!--            v-model="formData.contact.email"-->
<!--            full-width-->
<!--            label-float-->
<!--            max-length="250"-->
<!--            :label="$t('contacts.popups.editContact.placeholders.email')"-->
<!--            @input="control.$touch()"-->
<!--        ></mu-text-field>-->
<!--        <div class="error-messages" v-if="control.$invalid">-->
<!--          <div v-if="!control.email" class="error-message">{{ $t('errors.validation.email_invalid') }}</div>-->
<!--          <div v-if="!control.required" class="error-message">{{ $t('errors.validation.required') }}</div>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash;<mu-text-field v-model="formData.user.middle_name"-->
<!--                      full-width-->
<!--                     :label="$t('contacts.popups.editContact.editContactName')"></mu-text-field>&ndash;&gt;-->
<!--      <mu-text-field v-model="formData.contact.country"-->
<!--                     label-float-->
<!--                     full-width-->
<!--                     max-length="250"-->
<!--                     :label="$t('contacts.popups.editContact.placeholders.country')"></mu-text-field>-->
<!--      <mu-text-field v-model="formData.contact.city"-->
<!--                     full-width-->
<!--                     label-float-->
<!--                     max-length="250"-->
<!--                     :label="$t('contacts.popups.editContact.placeholders.city')"></mu-text-field>-->
<!--      <div class="control" :set="control = $v.formData.contact.company_name">-->
<!--        <mu-text-field v-model="formData.contact.company_name"-->
<!--                       full-width-->
<!--                       label-float-->
<!--                       max-length="250"-->
<!--                       :label="$t('contacts.popups.editContact.placeholders.company')"-->
<!--        ></mu-text-field>-->
<!--        <div class="error-messages" v-if="control.$invalid">-->
<!--          <div v-if="!control.required" class="error-message">{{ $t('errors.validation.required') }}</div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="control" :set="control = $v.formData.contact.company_website">-->
<!--        <mu-text-field-->
<!--            v-model="contactCompanyWebsite"-->
<!--            full-width-->
<!--            label-float-->
<!--            max-length="250"-->
<!--            :label="$t('contacts.popups.editContact.placeholders.company_url')"-->
<!--            @input="control.$touch()"-->
<!--        ></mu-text-field>-->
<!--        <div class="error-messages" v-if="control.$invalid">-->
<!--          <div v-if="!control.url" class="error-message">{{ $t('errors.validation.url_not_valid') }}</div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <mu-text-field v-model="formData.contact.company_position"-->
<!--                     full-width-->
<!--                     label-float-->
<!--                     max-length="250"-->
<!--                     :label="$t('contacts.popups.editContact.placeholders.position')"></mu-text-field>-->

<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.info') }}</div>-->
<!--      <mu-text-field v-model="formData.contact.company_description"-->
<!--                     full-width-->
<!--                     multi-line-->
<!--                     max-length="250"-->
<!--                     :rows="1"-->
<!--                     :rows-max="7"-->
<!--                     :label="$t('contacts.popups.editContact.placeholders.info')"></mu-text-field>-->

<!--      &lt;!&ndash;-->
<!--            <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.preferred_video_services') }}-->
<!--            </div>-->
<!--            <div>Выбор из нескольких чекбоксов</div>-->
<!--      &ndash;&gt;-->
<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.documents') }}</div>-->
<!--      <upload-files-->
<!--          :files="formData.contact.files"-->
<!--          deleteRoute="contact"-->
<!--          @filesData="handleFiles"></upload-files>-->

<!--      <div class="action-buttons">-->
<!--        <transition name="fade-statusmessage">-->
<!--          <div class="status-message status-message-success" v-if="this.editContactSuccess">-->
<!--            <div class="message">{{ $t('contacts.popups.editContact.editSuccessful') }}</div>-->
<!--          </div>-->
<!--        </transition>-->
<!--        <div class="activity-indicator" v-if="this.editContactLoading">-->
<!--          <mu-circular-progress-->
<!--              :width="22"-->
<!--          ></mu-circular-progress>-->
<!--        </div>-->
<!--        <span class="action-button action-button-transparent action-button-cancel" @click="cancel()">{{ $t('button.cancel') }}</span>-->
<!--        &lt;!&ndash;<span class="action-button action-button-preview" @click="preview()">{{ $t('button.preview') }}</span>&ndash;&gt;-->
<!--        <span class="action-button action-button-save" @click="submitUserForm()"-->
<!--              :class="{'action-button-save-disabled' : this.editContactLoading || $store.state.uploadImageStore.loading}"-->
<!--        >{{ $t('button.save') }}</span>-->
<!--      </div>-->

<!--    </div>-->
<!--    <div class="column column-edit-promopage" v-if="showCompany"-->
<!--         :class="{'column-busy' : this.editPromoPageLoading }"-->
<!--         style="display: none !important;"-->
<!--    >-->
<!--      <h3 class="column-title">{{ $t('contacts.popups.editContact.yourCompanyInfo') }}</h3>-->

<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.photos_and_video') }}</div>-->
<!--      <div class="form-section-descriptor">{{ $t('contacts.popups.editContact.form_sections.photos_and_video_descriptor') }}</div>-->
<!--      <upload-photos-and-video-promopage-->
<!--            :photosAndVideo="setPromoPagePhotos()"-->
<!--            deleteRoute="promoPagePhotos"-->
<!--            :externalId="this.formData.companyEdit.external_id"-->
<!--            @photosAndVideoData="handlePromoPagePhotosAndVideos">-->
<!--      </upload-photos-and-video-promopage>-->

<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.website') }}</div>-->

<!--      <div class="control" :set="control = $v.formData.companyEdit.website">-->
<!--        <mu-text-field-->
<!--            v-model="companyWebsiteUrl"-->
<!--            label-float-->
<!--            full-width-->
<!--            max-length="250"-->
<!--            :label="$t('contacts.popups.editContact.placeholders.website')"-->
<!--            @input="control.$touch()"-->
<!--        ></mu-text-field>-->
<!--        <div class="error-messages" v-if="control.$invalid">-->
<!--          <div v-if="!control.url" class="error-message">{{ $t('errors.validation.url_not_valid') }}</div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.title') }}</div>-->
<!--      <mu-text-field v-model="formData.companyEdit.title"-->
<!--                     label-float-->
<!--                     full-width-->
<!--                     multi-line-->
<!--                     max-length="75"-->
<!--                     :rows="1"-->
<!--                     :rows-max="2"-->
<!--      ></mu-text-field>-->
<!--      <country-city-fields-->
<!--          :countries="countryList"-->
<!--          :cities="cityList"-->
<!--          :selectedCountryId="formData.companyEdit.country_id"-->
<!--          :selectedCityId="formData.companyEdit.city_id"-->
<!--          @countryChanged="companyCountryChangeHandler"-->
<!--      ></country-city-fields>-->

<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.logo') }}</div>-->
<!--      <div class="form-section-descriptor">{{ $t('contacts.popups.editContact.form_sections.logo_descriptor') }}</div>-->
<!--      <upload-promo-image-->
<!--            class="upload-image-user upload-image-companylogo"-->
<!--            ref="uploadCompanyLogo"-->
<!--            :imageUrl="formData.companyEdit.logo_url"-->
<!--            @imageData="handleLogoUrl"-->
<!--      ></upload-promo-image>-->

<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.info') }}</div>-->
<!--      <div class="form-section-descriptor">{{ $t('contacts.popups.editContact.form_sections.info_descriptor') }}</div>-->
<!--      <mu-text-field v-model="formData.companyEdit.description"-->
<!--                     label-float-->
<!--                     full-width-->
<!--                     multi-line-->
<!--                     max-length="2000"-->
<!--                     :rows="1"-->
<!--                     :rows-max="9"-->
<!--      ></mu-text-field>-->


<!--      <div class="control" :set="control = $v.formData.companyEdit.video_stream_embed">-->
<!--        <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.video_stream_embed_code') }}</div>-->
<!--        <div class="form-section-descriptor">-->
<!--          {{ $t('contacts.popups.editContact.form_sections.video_stream_embed_code_descriptor') }}-->
<!--          <a-->
<!--            target="_blank"-->
<!--            :href="$t('contacts.popups.editContact.form_sections.video_stream_embed_code_descriptor_link')"-->
<!--          >-->
<!--            {{ $t('contacts.popups.editContact.form_sections.video_stream_embed_code_descriptor_link_text') }}-->
<!--          </a>-->
<!--        </div>-->
<!--        <mu-text-field-->
<!--          v-model="formData.companyEdit.video_stream_embed"-->
<!--          label-float-->
<!--          full-width-->
<!--          max-length="10000"-->
<!--          :label="$t('contacts.popups.editContact.placeholders.video_stream_embed_code')"-->
<!--          @input="control.$touch()"-->
<!--        ></mu-text-field>-->
<!--        <div class="error-messages" v-if="control.$invalid">-->
<!--          <div v-if="!control.videoStreamEmbed" class="error-message">{{ $t('errors.validation.video_stream_embed_not_valid') }}</div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="company-categories" v-if="eventTags && eventTags.length">-->
<!--        <div class="form-section-title relative">{{ $t('contacts.popups.editContact.form_sections.categories') }}-->
<!--          <span class="cur-p choose-a-category"-->
<!--                @click="toggleCategoryChooser"-->
<!--          >{{ $t('button.chooseCategory') }}</span>-->
<!--        </div>-->
<!--        <div class="company-categories-list"-->
<!--             v-if="formData.promoPageTags && formData.promoPageTags.length"-->
<!--        >-->
<!--          &lt;!&ndash; Chosen tags list &ndash;&gt;-->
<!--          <chooseCompanyCategories-->
<!--              mode="selectedOnly"-->
<!--              :show="true"-->
<!--              :allTags="formData.promoPageTags"-->
<!--              :selectedTagIds="formData.promoPageTagIds"-->
<!--              @tagListUpdated="displayTags($event)"-->
<!--          ></chooseCompanyCategories>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; Tag chooser popup &ndash;&gt;-->
<!--      <div class="category-chooser-dimmer"-->
<!--           v-if="showCategoryChooser"-->
<!--      ></div>-->

<!--      &lt;!&ndash; This one also needs v-if to trigger mounted() hook &ndash;&gt;-->
<!--      <chooseCompanyCategories-->
<!--          v-if="showCategoryChooser"-->
<!--          :show="showCategoryChooser"-->
<!--          :allTags="eventTags"-->
<!--          :selectedTagIds="formData.promoPageTagIds"-->
<!--          @close="toggleCategoryChooser"-->
<!--          @tagListUpdated="displayTags($event)"-->
<!--      ></chooseCompanyCategories>-->

<!--      &lt;!&ndash; / &ndash;&gt;-->

<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.team') }}</div>-->
<!--      <div class="form-section-descriptor">{{ $t('contacts.popups.editContact.form_sections.team_descriptor') }}</div>-->
<!--      <div class="team-members" v-if="teamMembers.length">-->
<!--        <div class="team-member"-->
<!--             v-for="(colleague, index) in teamMembers"-->
<!--             :key="index"-->
<!--        >-->
<!--          <div class="avatar"-->
<!--               :style="{'background-image': getColleagueAvatar(colleague) }">-->
<!--          </div>-->
<!--          <div class="team-member-info">-->
<!--            <div class="team-member-name"-->
<!--                 v-if="colleague.contact && colleague.contact.user && (colleague.contact.user.first_name || colleague.contact.user.last_name)">-->
<!--              <span v-if="colleague.contact.user.first_name">{{ colleague.contact.user.first_name }} </span>-->
<!--              <span v-if="colleague.contact.user.first_name">{{ colleague.contact.user.last_name }}</span>-->
<!--            </div>-->
<!--            <div class="team-member-phone" v-if="colleague.contact.user && colleague.contact.user.phone">{{-->
<!--              colleague.contact.user.phone }}-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash; TODO: deletion confirmation &ndash;&gt;-->
<!--          <div class="team-member-actions">-->
<!--            <div class="action action-delete-team-member cur-p"-->
<!--                 @click="deleteColleague(colleague, index)"-->
<!--                 v-if="colleague.role !== 'owner' && !colleague.dummy"-->
<!--            >×-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-section-subtitle">{{ $t('contacts.popups.editContact.form_sections.team_subtitle') }}</div>-->
<!--      <div class="relative">-->
<!--        <mu-text-field-->
<!--            v-model="formData.companyEdit.phone_number"-->
<!--            @keyup="addColleagueKeyupHandler()"-->
<!--            @keyup.enter="addColleagueByPhoneNumber()"-->
<!--            placeholder="+"-->
<!--            full-width-->
<!--        ></mu-text-field>-->
<!--        &lt;!&ndash; Геннадий попросил пока скрыть: <div class="field-bottom-description">{{ $t('contacts.popups.editContact.placeholders.addColleagueNumber') }}</div>&ndash;&gt;-->
<!--        <div class="field-bottom-warning">{{ $t('contacts.popups.editContact.useCorrectPhoneNumbers') }}</div>-->
<!--        <span class="form-section-action add-colleague-button cur-p"-->
<!--              :class="{'disabled': isAddColleagueDisabled() }"-->
<!--              @click="addColleagueByPhoneNumber()">{{ $t('button.add') }}</span>-->
<!--        <div class="add-colleague-error"-->
<!--             v-if="addColleagueError || deleteColleagueError"-->
<!--        >-->
<!--          <span v-if="addColleagueError">{{ $t('errors.addColleagueError') }}</span>-->
<!--          <span v-if="deleteColleagueError">{{ $t('errors.deleteColleagueError') }}</span>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.documents') }}</div>-->
<!--      <div class="form-section-descriptor">{{ $t('contacts.popups.editContact.form_sections.documents_descriptor') }}</div>-->
<!--      <upload-promo-files-->
<!--          :files="this.formData.promoPageFiles"-->
<!--          deleteRoute="promoPage"-->
<!--          :externalId="this.formData.companyEdit.external_id"-->
<!--          @filesData="handlePromopageFiles"></upload-promo-files>-->

<!--      <div class="form-section-products">-->
<!--        <div class="form-section-title">{{ $t('contacts.popups.editContact.form_sections.products') }}</div>-->
<!--        <div class="form-section-descriptor">{{ $t('contacts.popups.editContact.form_sections.products_descriptor') }}</div>-->
<!--        <div class="products-list" v-if="formData.promoPageProducts">-->
<!--          <draggable-->
<!--            v-model="formData.promoPageProducts"-->
<!--            v-bind="productDragOptions"-->
<!--            @start="productDrag=true"-->
<!--            @end="productDrag=false">-->

<!--            <transition-group type="productList" :name="!productDrag ? 'flip-list' : null">-->
<!--              <div class="product-item"-->
<!--               v-for="(product, index) in formData.promoPageProducts"-->
<!--               :key="'product-' + index"-->
<!--            >-->
<!--            <div class="product-item-content">-->
<!--              <div class="product-thumbnail"-->
<!--                   :style="{ 'background-image' : (product.photo_url ? 'url(' + product.photo_url + ')' : 'none') }"-->
<!--              ></div>-->
<!--              <div class="product-info">-->
<!--                <div class="product-info-content">-->
<!--                  <div class="product-name">{{ product.name }}</div>-->
<!--                  <div class="product-description">{{ product.description }}</div>-->
<!--                  <div class="product-actions">-->
<!--                  <span class="cur-p product-action product-action-delete"-->
<!--                        @click="deleteProduct(product, index)"-->
<!--                  >-->
<!--                    {{ $t('button.delete') }}-->
<!--                  </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--            </transition-group>-->
<!--          </draggable>-->
<!--        </div>-->
<!--        <div>-->
<!--          <upload-product-image-->
<!--              ref="promoPageProductImageUploader"-->
<!--              :externalId="this.formData.companyEdit.external_id"-->
<!--              @productImageData="handlePromopageProducts">{{ $t('contacts.popups.editContact.placeholders.productImage')-->
<!--            }}-->
<!--          </upload-product-image>-->
<!--        </div>-->

<!--        <mu-text-field-->
<!--            v-model="formData.companyEdit.addProductItem.name"-->
<!--            full-width-->
<!--            label-float-->
<!--            max-length="250"-->
<!--            :label="$t('contacts.popups.editContact.placeholders.productTitle')"-->
<!--        ></mu-text-field>-->
<!--        <mu-text-field v-model="formData.companyEdit.addProductItem.description"-->
<!--                       label-float-->
<!--                       full-width-->
<!--                       max-length="1000"-->
<!--                       :label="$t('contacts.popups.editContact.placeholders.productDescription')"></mu-text-field>-->

<!--        <div class="form-section-actions">-->
<!--          <span-->
<!--              class="form-section-action"-->
<!--              :class="{'form-section-action-disabled': isPushProductButtonDisabled() }"-->
<!--              @click="pushProductItem"-->
<!--          >{{ $t('button.add') }}</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="action-buttons">-->
<!--        <transition name="fade-statusmessage">-->
<!--          <div class="status-message status-message-success" v-if="this.editPromoPageSuccess">-->
<!--            <div class="message">{{ $t('contacts.popups.editContact.editPromoPageSuccessful') }}</div>-->
<!--          </div>-->
<!--        </transition>-->
<!--        <div class="activity-indicator" v-if="this.editPromoPageLoading">-->
<!--          <mu-circular-progress-->
<!--              :width="22"-->
<!--          ></mu-circular-progress>-->
<!--        </div>-->
<!--        <span class="action-button action-button-transparent action-button-cancel" @click="cancel()">{{ $t('button.cancel') }}</span>-->
<!--        &lt;!&ndash;<span class="action-button action-button-preview" @click="preview()">{{ $t('button.preview') }}</span>&ndash;&gt;-->
<!--        <span class="action-button action-button-save" @click="submitCompanyForm()"-->
<!--              :class="{'action-button-save-disabled' : this.editPromoPageLoading || this.$store.state.uploadPromopageFilesStore.loading}"-->
<!--        >{{ $t('button.save') }}</span>-->
<!--      </div>-->
<!--    </div>-->
    <createPromoCompany v-if="showCreatePromo"></createPromoCompany>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import { email, maxLength, required, url } from 'vuelidate/lib/validators';
//
// import uploadImage from "../uploadImages/uploadImage";
// import uploadPromoImage from "../uploadImages/uploadPromoImage";
// import uploadFiles from "../uploadFiles/uploadFiles";
// import uploadPromoFiles from "../uploadFiles/uploadPromoFiles";
// import uploadPhotosAndVideoPromopage from "../uploadFiles/uploadPhotosAndVideoPromopage";
// import uploadProductImage from "../uploadImages/uploadProductImage";
// import countryCityFields from "../promoPage/countryCityFields";
import createPromoCompany from "./createPromoCompany";
import chooseCompanyCategories from "../popups/chooseCompanyCategories";
import ValidationHelper from '@/_helpers/validation.helper';
import draggable from 'vuedraggable';

import _cloneDeep from 'lodash.clonedeep';

export default {
  name: 'editUserInfoPage',
  components: {
    // uploadPhotosAndVideoPromopage,
    // uploadImage,
    // uploadFiles,
    // uploadPromoFiles,
    // uploadPromoImage,
    // uploadProductImage,
    // countryCityFields,
    createPromoCompany,
    // chooseCompanyCategories,
    // draggable
  },
  computed: {
    ...mapState('locationStore', ['countryList', 'cityList', 'loading']),
    ...mapState('userStore', ['editUserInfo']),
    ...mapState('contactStore', ['callContact', 'editContact', `editContactLoading`, `editContactSuccess`]),
    ...mapState('promoStore', ['editPromoPageLoading', 'editPromoPageSuccess', 'promoOwnerId', 'productsListStandalone']),
    ...mapState({
      user_info: state => state.userStore.user_info,
      contact_info: state => state.contactStore.contact,
      promoPage: state => state.promoStore.myPromoPage,
      eventInfo: state => state.eventStore.eventInfo,
      eventTags: state => state.eventStore.eventTags,
    }),
    productDragOptions() {
      return {
        animation: 200,
        group: "productList",
        disabled: false,
      };
    },
    contactCompanyWebsite: {
      get: function () {
        return this.formData.contact.company_website || 'https://';
      },
      set: function (url) {
        this.formData.contact.company_website = url === 'https://' ? '' : url;
      },
    },
    companyWebsiteUrl: {
      get: function () {
        return this.formData.companyEdit.website || 'https://';
      },
      set: function (url) {
        this.formData.companyEdit.website = url === 'https://' ? '' : url;
      },
    },
  },
  watch: {
    user_info: {
      deep: true,
      immediate: true,
      handler() {
        this.userInfo = this.user_info;
        this.formData.files = this.user_info.files;
        if (this.userInfo.companies && this.userInfo.companies[0]) {
          this.formData.company.id = this.userInfo.companies[0].id;
        }

        this.setUserData();

      }
    },
    contact_info: {
      deep: true,
      immediate: true,
      handler() {
        this.setContactData();

        // set image
        if (this.$refs.userPhotoUploader && this.contact_info.photo_url) {
          this.$refs.userPhotoUploader.showExternalImage(this.contact_info.photo_url);
        }
      }
    },
    promoPage: {
      deep: true,
      handler() {
        // Show company form only when promo-page has id
        this.showCompany = !!this.promoPage.id;

        this.showCreatePromo = this.eventInfo.personal.is_creator;

        this.formData.companyEdit.website = this.promoPage.website;
        this.formData.companyEdit.video_stream_embed = this.promoPage.video_stream_embed;
        this.formData.companyEdit.external_id = this.promoPage.external_id;
        this.formData.companyEdit.description = this.promoPage.description;
        this.formData.companyEdit.title = this.promoPage.title;
        if (this.promoPage.country) {
          this.formData.companyEdit.country_id = this.promoPage.country.id;
          this.requestCompanyCityList(this.promoPage.country.id);
        }
        if (this.promoPage.city) {
          this.formData.companyEdit.city_id = this.promoPage.city.id;
        }
        if (this.$refs.uploadCompanyLogo) {
          this.$refs.uploadCompanyLogo.showExternalImage(this.promoPage.logo_url);
        }
        this.formData.promoPageFiles = _cloneDeep(this.promoPage.files);
        this.formData.promoPagePhotos = _cloneDeep(this.promoPage.photos);
        if (this.promoPage.attached_contacts) {
          this.teamMembers = this.promoPage.attached_contacts.filter(function (item) {
            // return (item.role && (item.role === 'assistant' || item.role === 'owner'));
            return !!item.role;
          });
        }
        // Products list to display in the form
        if (this.promoPage.products) {
          this.formData.promoPageProducts = _cloneDeep(this.promoPage.products);
        }
        // Tags list to display in the form
        if (this.promoPage.tags) {
          this.formData.promoPageTags = _cloneDeep(this.promoPage.tags); // [{}, {}, ... {}] structure with tag data
          this.formData.promoPageTagIds = this.formData.promoPageTags.map((x) => x.id); // [1,9,3,43,]
        }
      }
    },
    editContactSuccess: {
      handler() {
        if (this.editContactSuccess) {
          setTimeout(this.removeEditContactMessage, 10000);
        }
      }
    },
    editPromoPageSuccess: {
      handler() {
        if (this.editPromoPageSuccess) {
          setTimeout(this.removeEditPromoPageMessage, 10000);
        }
      }
    },
    // Для обновления списков продукции без обновления всего промопейджа
    productsListStandalone: {
      handler(newValue) {
        this.formData.promoPageProducts = _cloneDeep(newValue);
      }
    }
  },
  data: () => ({
    productDrag: false,
    teamMembers: [], // список сотрудников. Фильтруется в watch/promoPage
    addColleagueButtonDisabled: false,
    addColleagueError: false,
    deleteColleagueError: false,
    userInfo: {},
    contactInfo: {},
    userCompanyInfo: {},
    openEdit: false,
    showCompany: false,
    showCreatePromo: false,
    showCategoryChooser: false,
    errors: {
      website: ''
    },
    formData: {
      user: {
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        country: '',
        city: '',
        company_name: '',
        company_description: '',
        company_position: '',
        company_website: '',
        photo_url: '',
        companies: []
      },
      contact: {
        id: 0,
        event_id: 0,
        name: "",
        surname: "",
        email: "",
        photo_url: "",
        country: "",
        city: "",
        company_name: "",
        company_website: "",
        company_position: "",
        company_description: "",
        notes: "",
        user: {},
        is_favorite: true,
        unread_messages: null,
        files: []
      },
      company: {
        position: '',
        title: '',
        description: '',
        website_url: '',
        id: null,
      },
      contact_id: '',
      companyEdit: {
        website: '',
        video_stream_embed: '',
        external_id: '',
        logo_url: '',
        description: '',
        title: '',
        phone_number: '',
        country_id: 0,
        city_id: 0,
        addProductItem: {
          photo_url: '',
          name: '',
          description: '',
          sorting: 0,
        }
      },
      files: [],
      promoPageFiles: [],
      photos: [],
      promoPagePhotos: [],
      promoPageProducts: [], // company product list, AW-380
      promoPageTags: [], // company tags (categories / subcategories), AW-477
      promoPageTagIds: [], // flat list of selected tag ids
      isProductListUpdating: false,
    }
  }),
  mounted() {
    this.formData.contact.event_id = this.$route.params.eventId;
    this.$store.dispatch("locationStore/countryList");
    this.populateEventTags();
  },
  validations: {
    formData: {

      contact: {
        name: {
          required,
        },
        surname: {
          required,
        },
        email: {
          maxLength: maxLength(250),
          email,
          required,
        },
        company_website: {
          maxLength: maxLength(250),
          url,
        },
        video_stream_url: {
          maxLength: maxLength(250),
          url,
        },
        company_name: {
          required,
        }
      },

      companyEdit: {
        website: {
          maxLength: maxLength(250),
          url,
        },
        video_stream_embed: {
          videoStreamEmbed: ValidationHelper.isValidVideoStreamEmbed,
        }
      },

    },
  },
  methods: {

    isPushProductButtonDisabled() {
      return this.isProductListUpdating || !this.formData.companyEdit.addProductItem.photo_url || !this.formData.companyEdit.addProductItem.description || !this.formData.companyEdit.addProductItem.name;
    },

    /* Handler for event=tagListUpdated
     */
    displayTags(selectedTags) {
      this.formData.promoPageTags = selectedTags.filter((x) => x.isSelected);
      this.formData.promoPageTagIds = this.formData.promoPageTags.map((x) => x.id);
    },
    /* Populates eventTags in store
     */
    async populateEventTags(){ //:TODO //commit goes to eventStore
      const tags = await this.$store.dispatch("eventStore/callEventTags", this.$route.params.eventId);
      if(tags && tags.data && typeof tags.data.List != 'undefined')
        this.$store.commit("eventStore/setEventTags", tags.data.List);
    },

    /* Toggle category chooser popup/overlay
     */
    toggleCategoryChooser() {
      this.showCategoryChooser = !this.showCategoryChooser;
    },

    /* City list requested after company country id is known
     * Separate method because contact form is planned to have
     * country and city, too
     */
    requestCompanyCityList() {
      if (this.formData.companyEdit.country_id) {
        // this.$store.dispatch("locationStore/cityList", this.formData.companyEdit.country_id)
      }
    },

    /* City list requested after company country id is known
     */
    companyCountryChangeHandler(value){
      this.formData.companyEdit.country_id = value.country;
      if(value.city){
        this.formData.companyEdit.city_id = value.city;
      } else {
        this.formData.companyEdit.city_id = null;
      }
      this.requestCompanyCityList();
    },

    /* returns url() for background-image
      */
    getColleagueAvatar(colleague) {
      let photoUrl = require('../../../assets/images/no-avatar-300x300.png');
      if (colleague && colleague.contact && colleague.contact.photo_url) {
        photoUrl = colleague.contact.photo_url;
      }
      return 'url(' + photoUrl + ')';
    },

    /* Should the add colleague button be disabled
      */
    isAddColleagueDisabled() {
      if (this.addColleagueButtonDisabled)
        return true;

      return this.formData.companyEdit.phone_number.length < 8;
    },

    /* Gets photos from store and shows them inside upload-photos component
       */
    setPromoPagePhotos() {
      return this.promoPage.photos;
    },

    removeEditContactMessage() {
      this.$store.commit('contactStore/setEditContactSuccess', false);
    },

    removeEditPromoPageMessage() {
      this.$store.commit('promoStore/setEditPromoPageSuccess', false);
    },

    setUserData() {

      // user data
      for (let key in this.formData.user) {
        this.formData.user[key] = this.userInfo[key];
      }

      this.$store.dispatch('contactStore/callContact', this.$route.params.eventId);

    },
    setContactData() {
      // Do not do anything if we already have contact id.
      // No need to re-request promo-page
      if (this.contact_id === this.contact_info.id) {
        return;
      }
      this.contact_id = this.contact_info.id;
      if (this.contact_id) {
        this.getPromoPageByContactId();
        this.showCompany = true;
      } else {
        this.showCompany = false;
      }

      // contact data
      for (let key in this.formData.contact) {
        if (this.contact_info[key]) {
          this.formData.contact[key] = this.contact_info[key];
        }
      }

    },
    handlePhotoUrl(data) {
      this.formData.contact.photo_url = data.image;
      this.$store.commit('contactStore/setContactPhotoURL', data.image);
    },
    handleLogoUrl(data) {
      this.formData.companyEdit.logo_url = data.image;
    },
    handleFiles(data) {
      this.formData.contact.files = data;
    },
    handlePromopageFiles(data) {
      this.formData.promoPageFiles = data || [];
    },
    handlePromopageProducts(data) {
      this.formData.companyEdit.addProductItem.photo_url = data ? data : '';
    },
    handlePromoPagePhotosAndVideos(data) {
      this.formData.promoPagePhotos = data || [];
    },
    /* Обработчик нажатия на «Добавить» продукт.
     * Добавляет новому продукту свойство sorting.
     * Кладет урл, фотку и описание в массив formData.promoPageProducts
     * НЕ СОХРАНЯЕТ. Сохранение делается в submitUserForm
     */
    pushProductItem() {
      // spreading the object to make a copy of it instead of just a reference of the temporary object
      const productBeingAdded = {...this.formData.companyEdit.addProductItem};

      // updating the sort property in product being added
      productBeingAdded.sorting = this.formData.promoPageProducts.length;

      this.formData.promoPageProducts.push(productBeingAdded)

      // clear addProductItem
      for (const i in this.formData.companyEdit.addProductItem) {
        this.formData.companyEdit.addProductItem[i] = '';
      }

      // Clear the photo from product image uploader
      this.$refs.promoPageProductImageUploader.showExternalImage('');
    },

    /* Обработчик нажатия на «Удалить» продукт.
     * Запускает action и удаляет из массива promoPageProducts
     */
    async deleteProduct(product, index) {
      // If a product has an id, it means that the product
      // has been saved in the DB, have to delete it from there
      if (product.id) {
        // dispatch delete product
        await this.$store.dispatch('promoStore/deletePromoPageProduct', {
          event_id: this.$route.params.eventId,
          external_id: this.formData.companyEdit.external_id,
          product_id: product.id
        })
      }

      // delete an item from the promoPageProducts array
      // for visual change
      this.formData.promoPageProducts.splice(index, 1);
    },
    submitUserForm() {
      this.$v.formData.contact.$touch();
      if (this.$v.formData.contact.$pending || this.$v.formData.contact.$invalid) {
        /* vuelidation error(s) */
        return;
      }

      let contactDataToSend = {};
      for (let i in this.formData.contact) {
        if (i === 'files' || i === 'user' || i === 'is_favorite' || i === 'id' || i === 'notes') {
          continue;
        }
        contactDataToSend[i] = this.formData.contact[i];
      }

      this.$store.dispatch('contactStore/editContact', contactDataToSend);

      // Отправка файлов (документов) контакта
      for (let i = 0; i < this.formData.contact.files.length; i++) {
        // Если у файла есть id или tempUploadedFlag, значит, не нужно его еще раз отправлять
        if (this.formData.contact.files[i].id || this.formData.contact.files[i].tempUploadedFlag || !this.formData.contact.files[i].url) {
          continue;
        }
        // Отправляем при создании файла только url и filename
        let fileInfoToSend = {
          url: this.formData.contact.files[i].url,
          filename: this.formData.contact.files[i].name,
          event_id: this.formData.contact.event_id
        }
        this.$store.dispatch('contactStore/putContactFile', fileInfoToSend).then(() => {
          this.formData.contact.files.splice([i], 1, {
            ...this.formData.contact.files[i],
            tempUploadedFlag: true
          })
        });
      }
    },
    submitCompanyForm() {
      this.$v.formData.companyEdit.$touch();
      if (this.$v.formData.companyEdit.$pending || this.$v.formData.companyEdit.$invalid) {
        /* vuelidation error(s) */
        return;
      }

      // Disabling properly in addition to visual disabling in CSS with pointer-events: none
      if(this.isPromoPageLoading || this.$store.state.uploadPromopageFilesStore.loading){
        return;
      }

      let payload = {
        event_id: this.formData.contact.event_id,
        external_id: this.formData.companyEdit.external_id,
        video_stream_embed: this.formData.companyEdit.video_stream_embed,
        website: this.formData.companyEdit.website,
        logo_url: this.formData.companyEdit.logo_url,
        description: this.formData.companyEdit.description,
        title: this.formData.companyEdit.title,
        country_id: this.formData.companyEdit.country_id,
        city_id: this.formData.companyEdit.city_id,
        tags: this.formData.promoPageTags.map((x) => x.id)
      };

      this.$store.dispatch('promoStore/updatePromoPage', payload);

      // Отправка документов
      if (this.formData.promoPageFiles) {
        for (let i = 0; i < this.formData.promoPageFiles.length; i++) {
          // Если у файла есть id, значит, не нужно его еще раз отправлять
          // Также параноидальная проверка на отсутствие обязательного поля url
          if (!this.formData.promoPageFiles[i] || this.formData.promoPageFiles[i].id || this.formData.promoPageFiles[i].tempUploadedFlag || !this.formData.promoPageFiles[i].url) {
            continue;
          }
          // Отправляем при создании файла только url и name
          let fileInfoToSend = {
            url: this.formData.promoPageFiles[i].url,
            filename: this.formData.promoPageFiles[i].name
          };

          this.$store.dispatch('promoStore/putFileUrlPromoPage', {
            data: fileInfoToSend,
            event_id: this.formData.contact.event_id,
            external_id: this.formData.companyEdit.external_id
          }).then(() => {
            // Setting the local flag for each new object in that array. Since we have an SPA,
            // a user can stay on the page after submitCompanyForm(), which leaves new files
            // without an file.id field which we can't yet update from the backend
            // using .splice because of https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            // TODO: update file objects with id after saving on backend, if that is possible at the moment
            this.formData.promoPageFiles.splice(i, 1, {
              ...this.formData.promoPageFiles[i],
              tempUploadedFlag : true
            });
          });
        }
      }


      // Отправка фото и видео
      if (this.formData.promoPagePhotos) {
        for (let i = 0; i < this.formData.promoPagePhotos.length; i++) {
          // Если у файла есть id, значит, не нужно его еще раз отправлять
          // Также параноидальная проверка на отсутствие обязательного поля url
          if (this.formData.promoPagePhotos[i].id || this.formData.promoPagePhotos[i].tempUploadedFlag || !this.formData.promoPagePhotos[i].url) {
            continue;
          }
          // Отправляем при создании файла только url и name
          let fileInfoToSend = {
            url: this.formData.promoPagePhotos[i].url,
            filename: this.formData.promoPagePhotos[i].name
          }
          this.$store.dispatch('promoStore/putPhotoVideoUrlPromoPage', {
            data: fileInfoToSend,
            event_id: this.formData.contact.event_id,
            external_id: this.formData.companyEdit.external_id
          }).then(() => {
            // Setting the local flag for each new object in that array. Since we have an SPA,
            // a user can stay on the page after submitCompanyForm(), which leaves new files
            // without an file.id field which we can't yet update from the backend
            // using .splice because of https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            // TODO: update file objects with id after saving on backend, if that is possible at the moment
            this.formData.promoPagePhotos.splice(i, 1, {
              ...this.formData.promoPagePhotos[i],
              tempUploadedFlag : true
            });
          });
        }
      }


      // Отправка списка продукции
      this.saveProducts();

    },

    // Сохранение продуктов:
    // - по нажатию Добавить продукт НЕ СОХРАНЯЕТСЯ, а только помещается в конец временного массива.
    // - по нажатию Сохранить список продуктов сохраняется как массив dispatch-промисов.
    // - по Promise.all список продуктов обновляется, запустив отдельный action, который не мутирует весь state.promoPage
    async saveProducts() {
      const resultingPromises = [];

      this.isProductListUpdating = true;

      if (this.formData.promoPageProducts) {
        for (let i = 0; i < this.formData.promoPageProducts.length; i++) {

          // Место в списке сортировки
          // Ноль не шлём — бэк игнорит его
          this.formData.promoPageProducts[i].sorting = i+1;

          // Если есть id, редактируем, а не создаём новый
          // TODO: не отправлять (continue), если нет изменений
          let method = 'put';
          if (this.formData.promoPageProducts[i].id) {
            method = 'patch';
          }

          // Отправляем при создании файла только url и name
          let productInfoToSend = {
            photo_url: this.formData.promoPageProducts[i].photo_url,
            name: this.formData.promoPageProducts[i].name,
            description: this.formData.promoPageProducts[i].description,
            sorting: this.formData.promoPageProducts[i].sorting,
          }

          // Array of promises to be used in .all
          const payload = {
            data: productInfoToSend,
            event_id: this.formData.contact.event_id,
            external_id: this.formData.companyEdit.external_id
          }

          switch (method) {
            case 'put':
              resultingPromises.push(this.$store.dispatch('promoStore/putPromoPageProduct', payload));
              break;
            case 'patch':
              payload.product_id = this.formData.promoPageProducts[i].id;
              resultingPromises.push(this.$store.dispatch('promoStore/patchPromoPageProduct', payload));
              break;
            default:
              resultingPromises.push(null);
          }

        }

        // Updates the product list with IDs from backend
        await Promise.all(resultingPromises).then(() => {
          this.$store.dispatch('promoStore/getProductsFromPromoPage', {
            eventId: this.$route.params.eventId,
            externalId: this.formData.companyEdit.external_id,
          })
        });

        this.isProductListUpdating = false;

      }
    },

    /* Обработчик клика по кнопке «Cancel»
       * TODO: выжать из начальства и дизайнера техническое задание для этой непонятной кнопки. Предыдущие попытки были неуспешными.
       */
    cancel() {
      // window.location.reload(); // может, так?
    },

    /* Обработчик нажатия в поле ввода номера телефона коллеги
       */
    addColleagueKeyupHandler() {
      this.addColleagueError = false;
      this.formData.companyEdit.phone_number = '+' + this.formData.companyEdit.phone_number
              .replace(/\++/g,'');
    },

    /* Обработчик клика по .add-colleague-button
       */
    async addColleagueByPhoneNumber() {
      let currentPhoneNumber = this.formData.companyEdit.phone_number;
      let payload = {
        event_id: this.formData.contact.event_id,
        external_id: this.formData.companyEdit.external_id,
        phone: this.formData.companyEdit.phone_number,
      };

      this.addColleagueButtonDisabled = true;
      let result = await this.$store.dispatch('promoStore/putUserPromoPage', payload);

      if (!result || !result.status === 202) {
        // если результата нет или статус не 202, вероятно, что случилась ошибка )
        this.addColleagueError = true;
      } else {
        // а в эту ветку попадаем, если результат со статусом 202. Успех действия.
        this.addColleagueError = false; // прячем тексты ошибок, если они были
        this.deleteColleagueError = false;
        // TODO: make it more real, like, update state.promo-page.attached_contacts
        this.teamMembers.push({
          contact: {
            user: {
              phone: currentPhoneNumber
            }
          },
          dummy: true // TODO: убрать, когда доделается обновление списка из state
        });
        this.formData.companyEdit.phone_number = '';
      }
      this.addColleagueButtonDisabled = false;

    },

    /* Обработчик клика по .action-delete-team-member
       */
    async deleteColleague(colleague, index) {
      if (!colleague.contact.user.id || colleague.role === 'owner')
        return;

      this.addColleagueError = false;
      this.deleteColleagueError = false;

      let payload = {
        event_id: this.formData.contact.event_id,
        external_id: this.formData.companyEdit.external_id,
        user_id: colleague.contact.user.id,
      };

      const result = await this.$store.dispatch('promoStore/deleteUserPromoPage', payload);

      if (!result || !result.status === 202) {
        this.deleteColleagueError = true;
      } else if (typeof index != 'undefined') {
        this.teamMembers.splice(index, 1);
      }

    },
    getPromoPageByContactId() {
      let payload = {
        event_id: this.$route.params.eventId,
        contact_id: this.contact_id,
      };
      this.$store.dispatch('promoStore/getMyPromoPageContactId', payload)
    },
    setError(err, name) {
      this.errors[name] = err.message_key
    },

    /* Hide field error
       */
    rmErr(name) {
      if (name in this.errors)
        this.errors[name] = null;
    },
  }

}
</script>

<style scoped lang="scss">

  .fade-statusmessage-enter-active,
  .fade-statusmessage-leave-active {
    transition: opacity 2s;
  }

  .fade-statusmessage-enter,
  .fade-statusmessage-leave-to {
    opacity: 0;
  }

  .edit-user-cards {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    min-height: 100vh;
  }

  .column {
    background-color: #fff;
    border-radius: 25px;
    width: 392px;
    box-shadow: 5px 5px 15px rgba(55, 84, 170, 0.1);
    padding: 30px 30px 15rem;
    margin-right: 16px;
    margin-bottom: -78px;
    position: relative;

    &:first-child {
      margin-left: 8px;
    }

  }

  .column-busy {
    overflow: hidden;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: 30;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .column-title {
    color: #fc8887;
    text-align: center;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: #eaeaea;
    padding: 0 0 2.6rem;
    font-size: 1.6rem;
    font-weight: normal;
  }

  .form-section-title {
    font-weight: 300;
    position: relative;
    font-size: 1.6rem;
    margin: 5rem 0 2rem;

    &::before {
      width: 30px;
      height: 1px;
      content: "";
      background-color: #eaeaea;
      position: absolute;
      bottom: 0;
      right: 100%;
    }
  }

  .form-section-subtitle {
    font-weight: 300;
    opacity: 0.5;
  }

  .form-section-descriptor {
    font-weight: 300;
    color: #888;
    font-size: 1.2rem;
    margin: -1.5rem 0 1.5rem;

    a {
      color: $biletum-light-blue;

      &:hover,
      &:focus {
        color: #d00;
      }
    }
  }

  .field-bottom-description {
    color: $biletum-light-blue;
    font-size: 12px;
    font-weight: 300;
  }

  .mu-input,
  .mu-input-label {
    font-size: 1.3rem;
  }

  .mu-input {
    margin-bottom: 1rem;
    padding-bottom: 0;
    min-height: 0;
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 27px - 27px);  /* 27 = 30 - 3. This is for visual compensation due to rounded buttons */
    position: fixed;
    max-width: 330px;
    background-color: #fff;
    padding-top: 4rem;
    padding-bottom: 6rem;
    margin-bottom: 0;
    bottom: 0;
    z-index: $z-index-edit-page-fixed-action-buttons;
    box-shadow: 0 0 20px 5px #fff;

    .activity-indicator {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }

    .status-message {
      font-size: 1.3rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  .action-button {
    width: 30%;
    border-radius: 99px;
    font-size: 1.2rem;
    text-align: center;
    padding: 0.6rem 0.5rem 0.3rem;
    line-height: 1;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    &-cancel {
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      opacity: 0.9;
    }

    &-transparent {
      border-style: solid;
      border-width: 1px;
      border-color: #57c7ff;
      background-color: transparent;
      color: #57c7ff;
    }

    &-preview {
      background-color: #fc8887;
      color: #fff;
    }

    &-save {
      background-color: #00b6f8;
      color: #fff;

      &-disabled {
        background-color: #a8a8a8;
        pointer-events: none;
        cursor: default;
      }
    }

  }

  /* Categories */
  .company-categories {
    .choose-a-category {
      color: $biletum-light-blue;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      text-align: right;

      &:hover {
        opacity: 0.6;
      }

      &::before {
        content: "+";
      }
    }
  }

  /* Team */
  .team-members {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }

  .team-member {
    width: 25%;
    text-align: center;
    margin-bottom: 20px;
    word-wrap: break-word;

    .avatar {
      width: 60px;
      height: 60px;
      min-width: 60px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin: 0 auto 6px;
      border-radius: 99px;
    }

    &-info {
      font-size: 10px;
    }

    &-actions {
      text-align: center;

      .action {
        display: inline-block;
      }

      .action-delete-team-member {
        color: #d00;
      }
    }
  }


  .add-colleague-button {
    position: absolute;
    right: 0;
    z-index: 15;
    top: 10px;
    opacity: 1;
    border-radius: 99px;
    font-size: 1.2rem;
    box-sizing: border-box;
    color: #fff;
    background-color: #00b6f8;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0.3rem 1.25rem 0.1rem;

    &:hover {
      opacity: 0.65;
    }

    &.disabled {
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.25);
      color: #777;
    }
  }

  .add-colleague-error {
    font-size: 11px;
    padding-top: 0.6rem;
    color: #d00;
  }

  .form-section-products {
    .form-section-actions {
      text-align: right;
      padding: 2rem 0 0;
    }

    .form-section-action {
      display: inline-block;
      border-radius: 99px;
      font-size: 1.2rem;
      box-sizing: border-box;
      color: #fff;
      background-color: #00b6f8;
      cursor: pointer;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      min-width: 98px;
      text-align: center;
      padding: 0.3rem 0.4rem 0.1rem;

      &:hover {
        opacity: 0.75;
      }

      &-disabled {
        background-color: #ccc;
        color: #888;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .products-list {

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .flip-list-move {
      transition: transform 0.5s;
    }

    .no-move {
      transition: transform 0s;
    }

    .product-item {
      display: inline-block;
      width: 57px;
      margin: 0 34px 3rem 0;
      position: relative;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .product-item-content {
        max-width: 57px;
        position: relative;
        text-align: center;
      }

      .product-thumbnail {
        background-color: #f2f2f2;
        width: 100%;
        padding-bottom: 100%; /* Квадрат шириной в элемент-родитель */
        border-radius: 5px;
        background-size: contain;
        overflow: hidden;
        margin-bottom: 66px;
        background-position: center;
        background-repeat: no-repeat;
      }

      .product-info {
        position: absolute;
        top: 66px;
        left: 50%;
        max-width: 160%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        transform: translateX(-50%);
        font-size: 10px;
        line-height: 1.25;
      }

      .product-name {
        max-height: 2.5rem;
        text-overflow: ellipsis;
        margin-bottom: 0.75rem;
        overflow: hidden;
      }

      .product-description {
        max-height: 1.25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.75rem;
        opacity: 0.5;
      }

      .product-action-delete {
        color: #ff6c6c;
      }

    }

    /* For the sortable state */
    .product-item.sortable-ghost {
      /* empty product place is bordered (lazily via box-shadow),.. */
      .product-item-content {
        border-radius: 4px;
        box-shadow: 0 0 0 4px #fff, 0 0 0 6px $biletum-light-blue;

        /* and empty-looking */
        * {
          opacity: 0;
        }
      }

    }

  }

  .field-bottom-warning {
    color: #c50;
    font-size: 1.2rem;
    padding-top: 1rem;
  }

  .category-chooser-dimmer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $z-index-company-categories-chooser-dimmer;
    background-color: rgba(0, 0, 0, 0.41);
  }

</style>

<style lang="css">
  .edit-user-cards .column .mu-input.has-label.no-empty-state .mu-input-label,
  .edit-user-cards .column .mu-input.has-label.mu-input__focus .mu-input-label {
    font-size: 1.7rem !important;
  }
</style>

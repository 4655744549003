<template>
  <div class="column column-edit-promopage">
    <h3 class="column-title">{{ $t('contacts.popups.createCompany.title') }}</h3>
    <div class="form-section-title">{{ $t('contacts.popups.createCompany.addNew') }}</div>
    <div class="control">
      <div class="control" :set="control = $v.formData.title">
        <mu-text-field
          v-model="formData.title"
          label-float
          full-width
          max-length="50"
          :label="$t('contacts.popups.createCompany.companyName')"
        ></mu-text-field>
        <div class="error-messages" v-if="control.$invalid">
          <div v-if="!control.required" class="error-message">{{ $t('errors.validation.required') }}</div>
        </div>
      </div>
      <div class="control" :set="control = $v.formData.external_id">
      <mu-text-field
          v-model="formData.external_id"
          label-float
          full-width
          max-length="18"
          :label="$t('contacts.popups.createCompany.expoboothCode')"
      ></mu-text-field>
      <div class="error-messages" v-if="control.$invalid">
        <div v-if="!control.required" class="error-message">{{ $t('errors.validation.required') }}</div>
      </div>
    </div>
      <div class="control" :set="control = $v.formData.owner_phone">
        <phone-input
          :clearInput="rmPhoneNum()"
          class="phone-input"
          @phoneNumberReady="setPhoneNumber"
          @phoneMask="phoneLengthCheck"
          :searchSelector="'.column-edit-promopage'"
        ></phone-input>
        <div class="error-messages" v-if="control.$invalid">
          <div v-if="!control.required" class="error-message">{{ $t('errors.validation.required') }}</div>
          <div v-if="!control.phoneValid" class="error-message">{{ $t('errors.validation.phone_only_num_and_+') }}</div>
          <div v-if="!control.isRightLength" class="error-message">{{ $t('errors.validation.phone_num_short') }}</div>
        </div>
      </div>
      <div v-if="pavilions && pavilions.List.length">
        <mu-select v-model="formData.pavilion_id"
                   :placeholder="$t('contacts.popups.createCompany.pavilions')"
                   full-width
        >
          <mu-option v-for="(pavilion, index) in pavilions.List" :key="index"
                     :label="pavilion.name"
                     :value="pavilion.id"
                     :selected="formData.pavilion_id === pavilion.id"
          >{{ pavilion.name }}</mu-option>
        </mu-select>
        <div class="error-messages" v-if="errors.pavilion_id">
          <div class="error-message">{{ $t(errors.pavilion_id) }}</div>
        </div>
      </div>
      <div class="field-bottom-warning">{{ $t('contacts.popups.editContact.useCorrectPhoneNumbers') }}</div>
    </div>
    <div class="companiesList">
      <div class="form-section-title">{{ $t('navigation.list') }}</div>
      <perfect-scrollbar class="scroll-height">
        <div v-for="(company, key) in this.promoPageList" :key="key">
          <div class="company">
            <div class="item title">{{company.title}}</div>
            <div class="item">{{$t('contacts.popups.createCompany.expoboothCode')}}: {{company.external_id}}</div>
            <div class="item"></div>
            <div class="divider"></div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>

    <div class="action-buttons">
      <transition name="fade-statusmessage">
        <div class="status-message status-message-success" v-if="this.createPromoPageSuccess">
          <div class="message">{{ $t('contacts.popups.editContact.editPromoPageSuccessful') }}</div>
        </div>
      </transition>
      <transition name="fade-statusmessage">
        <div class="status-message status-message-error" v-if="this.errors.sendError">
          <div class="message">{{ $t(errors.sendError) }}</div>
        </div>
      </transition>
      <div class="activity-indicator" v-if="this.createPromoPageLoading">
        <mu-circular-progress
            :width="22"
        ></mu-circular-progress>
      </div>
      <span class="action-button action-button-transparent action-button-cancel" @click="cancel()">{{ $t('button.cancel') }}</span>
      <!--<span class="action-button action-button-preview" @click="preview()">{{ $t('button.preview') }}</span>-->
      <span class="action-button action-button-save" @click="createCompany()"
            :class="{'action-button-save-disabled' : this.createPromoPageLoading}"
      >{{ $t('button.save') }}</span>
    </div>
  </div>
</template>
<script>

import {mapState} from 'vuex';
import { maxLength, required} from 'vuelidate/lib/validators';
import ValidationHelper from "@/_helpers/validation.helper";
import PhoneInput from '@/_components/phone-input/phoneInput.vue';

export default {
  name: 'createPromoCompany',
  components: {PhoneInput},
  computed: {
    ...mapState({
      promoPageList: state => state.promoStore.promoPageList,
      createPromoPageLoading: state => state.promoStore.createPromoPageLoading,
      createPromoPageSuccess: state => state.promoStore.createPromoPageSuccess,
      promoPageError: state => state.promoStore.promoPageError,
      pavilions: state => state.promoStore.pavilions,
    }),
  },
  watch: {
    promoPageError: {
      deep: true,
      handler() {
        this.setError(this.promoPageError, 'sendError');
        this.formData.external_id = null;
        this.formData.owner_phone = null;
        this.formData.title = '';

        if (this.formData.external_id) {
          this.errors.external_id = null;
        } else {
          this.setError({message_key: 'errors.validation.field_is_required'}, 'external_id');
        }
        if (this.formData.owner_phone) {
          this.errors.owner_phone = null;
        } else {
          this.setError({message_key: 'errors.validation.field_is_required'}, 'owner_phone');
        }
        if (this.formData.title) {
          this.errors.title = '';
        } else {
          this.setError({message_key: 'errors.validation.field_is_required'}, 'title');
        }
      }
    },
    'formData.external_id': {
      handler() {
        this.validateExternalId();
      }
    },
    'formData.owner_phone': {
      handler() {
        this.validatePhone();
      }
    },
    'formData.title': {
      handler() {
        this.validateEventName()
      }
    },
    'formData.pavilion_id': {
      handler(){
        if(this.formData.pavilion_id) {
          this.errors.pavilion = '';
        } else {
          this.setError({message_key: 'errors.validation.field_is_required'}, 'pavilion');
        }
      }
    },
  },
  data: () => ({
    errors: {
      title: '',
      external_id: null,
      owner_phone: null,
      sendError: '',
      pavilion: ''
    },
    formData: {
      event_id: null,
      external_id: null,
      owner_phone: null,
      title: '',
      pavilion_id: null
    },
    phoneMask: ''
  }),
  created() {
    this.formData.event_id = this.$route.params.eventId;
    this.$store.dispatch('promoStore/getPavilion', { eventId: this.formData.event_id })
  },
  validations: {
    formData: {
      title: {
        required,
        maxLength: maxLength(50),
      },
      external_id: {
        required,
      },
      owner_phone: {
        required,
        isRightLength(){
          return ValidationHelper.isValidPhoneNumberLength(this.phoneMask, this.formData.owner_phone)
        },
        phoneValid: ValidationHelper.isValidPhoneNumber,
      },
    },
  },
  methods: {
    async createCompany() {
      this.$v.formData.$touch();
      if (this.$v.formData.$pending || this.$v.formData.$invalid) {
        /* vuelidation error(s) */
        return;
      }

      this.validateAll();
      if (this.validateEventName() &&
        this.validatePhone() &&
        this.validateExternalId()
      ) {
        let payload = {
          external_id: this.formData.external_id,
          owner_phone: this.formData.owner_phone,
          title: this.formData.title,
          pavilion_id: this.formData.pavilion_id
        };

        await this.$store.dispatch('promoStore/promoPageCreate', { payload, event_id: this.formData.event_id });
        this.$store.dispatch('promoStore/promoPageListAll', { event_id: this.formData.event_id });
      }
    },
    validateEventName() {
      if (!this.formData.title) {
        this.setError({ message_key: "errors.validation.type_event_name" }, "title");
      } else if (this.formData.title.length < 1) {
        this.setError({ message_key: "errors.validation.should_more_1_chars" }, "title");
      } else {
        this.rmErr("title");
        return true;
      }
    },
    phoneLengthCheck(phoneMask) {
      this.phoneMask = phoneMask;
    },
    // validatePhoneLength(){
    //   if (!this.phoneMask && !this.formData.owner_phone) { return 0 }
    //   let included = this.phoneMask.replace(/[^#\d+]/g, '');
    //   this.isPhoneLength = (included.length === this.formData.owner_phone.length)
    // },
    validatePhone() {
      let reg = /^[\[+]\d]?(?:[\d]*)$/;

      if (!(reg.test(this.formData.owner_phone))) {
        this.setError({ message_key: 'errors.validation.phone_only_num_and_+' }, 'owner_phone');
      } else if (!this.formData.owner_phone) {
        this.setError({message_key: 'errors.validation.field_is_required'}, 'owner_phone');
      } else {
        this.rmErr('owner_phone');
        return true;
      }
    },
    validateExternalId(){
      if (this.formData.external_id) {
        this.rmErr('external_id');
        return true;
      } else {
        this.setError({message_key: 'errors.validation.field_is_required'}, 'external_id');
      }
    },
    setError(err, name) {
      this.errors[name] = err.message_key
    },

    /* Hide field error
       */
    rmErr(name) {
      if (name in this.errors)
        this.errors[name] = null;
    },
    validateAll() {

      let isError = [];
      isError.push(this.validateEventName());
      isError.push(this.validatePhone());
      isError.push(this.validateExternalId());
      isError.forEach(item => {
        // console.log(item, 'arr item');
        if(!item){
          return false
        } else {
          return true
        }
      })
    },

    setPhoneNumber(phoneNumber) {
      this.formData.owner_phone = phoneNumber;
    },
    rmPhoneNum() {
      if (!this.formData.owner_phone) {
        return true
      }
    }
  }

}
</script>
<style scoped lang="scss">

.fade-statusmessage-enter-active,
.fade-statusmessage-leave-active {
  transition: opacity 2s;
}

.fade-statusmessage-enter,
.fade-statusmessage-leave-to {
  opacity: 0;
}

.edit-user-cards {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  min-height: 100vh;
}

.column {
  background-color: #fff;
  border-radius: 25px;
  width: 392px;
  box-shadow: 5px 5px 15px rgba(55, 84, 170, 0.1);
  padding: 30px 48px 15rem 56px;
  margin-right: 16px;
  margin-bottom: -78px;
  position: relative;

  &:first-child {
    margin-left: 8px;
  }

}

.column-busy {
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 30;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.column-title {
  color: #fc8887;
  text-align: center;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: #eaeaea;
  padding: 0 0 2.6rem;
  font-size: 1.6rem;
  font-weight: normal;
}

.form-section-title {
  font-weight: 300;
  position: relative;
  font-size: 1.6rem;
  margin: 5rem 0 2rem;

  &::before {
    width: 30px;
    height: 1px;
    content: "";
    background-color: #eaeaea;
    position: absolute;
    bottom: 0;
    right: 100%;
  }
}

.form-section-subtitle {
  font-weight: 300;
  opacity: 0.5;
}

.field-bottom-description {
  color: $biletum-light-blue;
  font-size: 12px;
  font-weight: 300;
}

.mu-input,
.mu-input-label {
  font-size: 1.3rem;
}

.mu-input {
  margin-bottom: 1rem;
  padding-bottom: 0;
  min-height: 0;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 27px - 27px); /* 27 = 30 - 3. This is for visual compensation due to rounded buttons */
  position: fixed;
  max-width: 330px;
  background-color: #fff;
  padding-top: 4rem;
  padding-bottom: 6rem;
  margin-bottom: 0;
  bottom: 0;
  z-index: 100;
  box-shadow: 0 0 20px 5px #fff;


  .activity-indicator {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  .status-message {
    font-size: 1.3rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .status-message-success {
    color: $biletum-light-blue;
  }

  .status-message-error {
    color: $biletum-red;
  }
}

.action-button {
  width: 30%;
  border-radius: 99px;
  font-size: 1.2rem;
  text-align: center;
  padding: 0.6rem 0.5rem 0.3rem;
  line-height: 1;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  &-cancel {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.9;
  }

  &-transparent {
    border-style: solid;
    border-width: 1px;
    border-color: #57c7ff;
    background-color: transparent;
    color: #57c7ff;
  }

  &-preview {
    background-color: #fc8887;
    color: #fff;
  }

  &-save {
    background-color: #00b6f8;
    color: #fff;

    &-disabled {
      background-color: #a8a8a8;
    }
  }

}


.add-colleague-button {
  position: absolute;
  right: 0;
  z-index: 15;
  top: 10px;
  width: 20px;
  height: 20px;
  opacity: 0.8;
  background-color: rgba(255, 255, 255, 0.1);

  &::before,
  &::after {
    border-radius: 5px;
    width: 100%;
    height: 4px;
    content: "";
    background-color: $biletum-light-blue;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -2px;
  }

  &::after {
    transform: rotate(90deg);
  }

  &:hover {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

.add-colleague-error {
  font-size: 11px;
  padding-top: 0.6rem;
  color: #d00;
}

.company {
  border-bottom: 2px solid $biletum-light-grey;
  padding: 26px 0;

  .item {
    text-align: left;
    font-size: 10px;
    font-family: Point, sans-serif;
    letter-spacing: 0;
    color: $biletum-black;
    margin-bottom: 10px;
  }

  .title {
    font-size: 12px;
    font-weight: 600;
  }
}

.scroll-height {
  height: 1605px;
}

.field-bottom-warning {
  color: #c50;
  font-size: 1.2rem;
  padding-top: 1rem;
}

.error-messages {
  margin-top: -8px;
}
</style>
